import React from 'react'
import MenuItem from './MenuItem'
import { Menu, MenuLabel, MenuPath } from '../../../../../constants/menus'
import { Navbar } from 'flowbite-react'
import Logo from '../../../../atoms/Logo'

interface DesktopNavBarProps {}

const DesktopNavBar: React.FC<DesktopNavBarProps> = () => {
  return (
    <div className="hidden md:block w-full">
      <div className="flex justify-between items-center w-full">
        {/* Brand Logo */}
        <Navbar.Brand as={'a'} href="/">
          <Logo />
        </Navbar.Brand>

        {/* Nav Menus */}
        <div className="flex gap-11 py-3 px-4 xl:px-0">
          {Object.values(Menu).map((each) => (
            <MenuItem name={MenuLabel[each]} path={MenuPath[each]} key={each} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default DesktopNavBar
