import React, { useCallback } from 'react'
import ImageGallery from '../../components/molecules/ImageGallery'
import showcases from '../../data/showcase'
import { toSnakeCase } from '../../utils'
import SloganBanner from '../../components/molecules/SloganBanner'

const Showcase: React.FC = () => {
  const getDescription = useCallback(
    (name: string, description: string) => description.split(new RegExp(`(${name})`, 'gi')),
    []
  )

  return (
    <>
      {showcases.map((showcase, index) => {
        const showBg = (index + 1) % 2 === 0

        return (
          <section className={`section showcase ${showBg ? 'bg-light-100' : ''} ${toSnakeCase(showcase.name)}`}>
            <div className="container mx-auto px-4 md:px-6">
              <div className="grid grid-cols-12 gap-6 lg:gap-8 ">
                <div
                  className={`col-span-12 lg:col-span-6 flex flex-col justify-center ${
                    showBg ? 'lg:order-2 lg:pl-20' : 'lg:order-1 lg:pe-20'
                  }`}
                >
                  <div className="mb-9">
                    <h2 className="section_title mb-2">{showcase.name}</h2>
                    <p className="section_sub_title">Creative showcase</p>
                  </div>

                  <p className="mb-11">
                    {getDescription(showcase.name, showcase.description).map((part, index) =>
                      part.toLowerCase() === showcase.name.toLowerCase() ? (
                        <span className="font-medium" key={index}>
                          {part}
                        </span>
                      ) : (
                        part
                      )
                    )}
                  </p>
                  <div className="hidden lg:block">
                    <ImageGallery images={showcase.all_images} isVideo={showcase.isVideo} />
                  </div>
                </div>

                <div
                  className={`col-span-12 lg:col-span-6  ${showBg ? 'lg:order-1' : 'lg:order-2'} ${
                    showcase.preview_images?.length <= 2 ? 'py-5 md:py-6 lg:py-8' : ''
                  }`}
                >
                  <div className="grid grid-cols-12 gap-2 md:gap-4">
                    {showcase.preview_images?.map((each, index) => (
                      <div
                        className={`${showcase.preview_images?.length === 1 ? 'col-span-12' : 'col-span-6'}`}
                        key={index + 1}
                      >
                        {showcase.isVideo ? (
                          <video className="w-full aspect-video  shadow-card" controls>
                            <source src={each} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <div
                            className="image-container"
                            style={showcase.preview_images?.length === 1 ? { aspectRatio: 'unset' } : undefined}
                          >
                            <img src={each} alt={`${showcase.name}'s showcase preview`} className={`bg-white`} />
                          </div>
                        )}
                      </div>
                    ))}

                    <div className="col-span-12 lg:hidden text-center mt-8">
                      <ImageGallery images={showcase.all_images} isVideo={showcase.isVideo} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
      })}

      <SloganBanner />
    </>
  )
}

export default Showcase
