export const getYearDifference = (inputYear: number): number => {
  const currentDate: Date = new Date()
  const currentYear: number = currentDate.getFullYear()
  const yearDifference: number = currentYear - inputYear
  return yearDifference
}

export const toSnakeCase = (str: string): string => {
  return str
    .replace(/\s+/g, '_')
    .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
    .replace(/^_/, '')
    .toLowerCase()
}
