import { Button } from 'flowbite-react'
import React from 'react'
import HeroBanner from './components/HeroBanner'
import OurServices from './components/OurServices'
import SloganBanner from '../../components/molecules/SloganBanner'
import FeaturedClients from './components/FeaturedClients'

const Home: React.FC = () => {
  return (
    <>
      <HeroBanner />

      <OurServices />

      <FeaturedClients />

      <SloganBanner />
    </>
  )
}

export default Home
