import React from 'react'
import Logo from '../../../atoms/Logo'
import { MenuPath } from '../../../../constants/menus'

import { Link } from 'react-router-dom'
import PhoneIcon from '../../../atoms/Icons/PhoneIcon'
import FacebookIcon from '../../../atoms/Icons/FacebookIcon'
import EmailIcon from '../../../atoms/Icons/EmailIcon'
import lightTheme from '../../../../theme/light-theme'
import {
  BRAND_EMAIL_PRIMARY,
  BRAND_FACEBOOK_URL,
  BRAND_INSTAGRAM_URL,
  BRAND_LINKEDIN_URL,
  BRAND_MESSENGER_URL,
  BRAND_PHONE_NUMBER_ARRAY,
  BRAND_TIKTOK_URL,
  BRAND_YOUTUBE_URL
} from '../../../../constants/brandInfo'
import InstagramIcon from '../../../atoms/Icons/InstagramIcon'
import YoutubeIcon from '../../../atoms/Icons/YoutubeIcon'
import TiktokIcon from '../../../atoms/Icons/TiktokIcon'
import Messenger from '../../../atoms/Icons/Messenger'
import LinkedIn from '../../../atoms/Icons/LinkedIn'

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <footer className="pt-8 pb-7 footer-container relative bg-light-100">
      <div className="container mx-auto px-4 md:px-2">
        <div className="flex flex-col justify-center items-center gap-4 md:gap-6">
          <Link to={MenuPath.HOME}>
            <Logo className="!h-20 md:!h-24" />
          </Link>

          <div className="gap-4 items-center hidden md:flex">
            <div className="flex gap-3 items-center">
              <PhoneIcon color={lightTheme.primary} />
              {BRAND_PHONE_NUMBER_ARRAY.map((phone, index) => (
                <Link to={`tel:${phone}`} key={index + 1}>
                  <span>
                    {phone} {phone.startsWith('+95') ? '(MM)' : '(TH)'}
                    {index + 1 !== BRAND_PHONE_NUMBER_ARRAY.length && ', '}
                  </span>
                </Link>
              ))}
            </div>

            <div className="flex gap-3 items-center">
              <EmailIcon color={lightTheme.primary} />
              {/* <Link to={'mailto:example@example.com?subject=Hello%20There&body=This%20is%20a%20test%20email.'}>
                <span>{BRAND_EMAIL_PRIMARY}</span>
              </Link> */}
              <Link to={`mailto:${BRAND_EMAIL_PRIMARY}?subject=Service%20Request`}>
                <span>{BRAND_EMAIL_PRIMARY}</span>
              </Link>
            </div>
          </div>

          <div className="flex items-center gap-6">
            <Link to={BRAND_FACEBOOK_URL} target="_blank">
              <FacebookIcon className="social_icon" />
            </Link>
            <Link to={BRAND_MESSENGER_URL} target="_blank">
              <Messenger className="social_icon" />
            </Link>
            <Link to={BRAND_LINKEDIN_URL} target="_blank">
              <LinkedIn className="social_icon" />
            </Link>
            {/* <Link to={BRAND_TIKTOK_URL} target="_blank">
              <TiktokIcon className="social_icon" />
            </Link> */}
          </div>

          <div className="flex-col gap-4 items-start flex md:hidden my-4">
            <div className="flex gap-3 items-center">
              <PhoneIcon color={lightTheme.primary} />
              {BRAND_PHONE_NUMBER_ARRAY.map((phone, index) => (
                <Link to={`tel:${phone}`} key={index + 1}>
                  <span>
                    {phone} {phone.startsWith('+95') ? '(MM)' : '(TH)'}
                    {index + 1 !== BRAND_PHONE_NUMBER_ARRAY.length && ', '}
                  </span>
                </Link>
              ))}
            </div>

            <div className="flex gap-3 items-center">
              <EmailIcon color={lightTheme.primary} />
              <Link to={`mailto:${BRAND_EMAIL_PRIMARY}?subject=Service%20Request`}>
                <span>{BRAND_EMAIL_PRIMARY}</span>
              </Link>
            </div>
          </div>
        </div>

        <div className="text-center mt-8">
          <p className=" text-darkestGray font-light copy-right-text text-[12px] sm:text-sm md:text-base">
            Copyright © 2024 Nest Marketing Ltd . All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
