import React from 'react'
import useBreakPoint from '../../../hooks/useBreakPoint'
import { Link } from 'react-router-dom'
import { MenuPath } from '../../../constants/menus'

const ErrorNotFound: React.FC = () => {
  const { md } = useBreakPoint()

  return (
    <div
      style={{ height: `${window.innerHeight - (md ? 414 : 446)}px` }}
      className={`flex justify-center items-center flex-col gap-3 px-4`}
    >
      <p className="text-4xl md:text-5xl font-medium">404</p>
      <p className="text-base md:text-lg text-center">
        Sorry, this page is not available. Go back to &nbsp;
        <Link to={MenuPath.HOME} className="text-primary">
          Home
        </Link>
      </p>
    </div>
  )
}

export default ErrorNotFound
