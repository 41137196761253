import { createBrowserRouter } from 'react-router-dom'
import ErrorNotFound from '../components/templates/errors/ErrorNotFound'
import MainLayout from '../components/templates/layouts/MainLayout'
import { MenuPath } from '../constants/menus'
import HomePage from '../pages/home'
import AboutPage from '../pages/about'
import ContactPage from '../pages/contact'
import ShowcasePage from '../pages/showcase'

const router = createBrowserRouter([
  {
    element: <MainLayout />,
    children: [
      {
        path: MenuPath.HOME,
        element: <HomePage />
      },
      {
        path: MenuPath.ABOUT,
        element: <AboutPage />
      },
      {
        path: MenuPath.SHOWCASE,
        element: <ShowcasePage />
      },
      {
        path: MenuPath.CONTACT,
        element: <ContactPage />
      },
      {
        path: '*',
        element: <ErrorNotFound />,
        errorElement: <ErrorNotFound />
      }
    ]
  }
])

export default router
