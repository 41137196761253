import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  BRAND_EMAIL_PRIMARY,
  BRAND_EMAIL_SECONDARY,
  BRAND_LOCATION,
  BRAND_PHONE_NUMBER_ARRAY
} from '../../constants/brandInfo'
import PhoneIcon from '../../components/atoms/Icons/PhoneIcon'
import EmailIcon from '../../components/atoms/Icons/EmailIcon'
import LocationIcon from '../../components/atoms/Icons/LocationIcon'
import { Button, FloatingLabel } from 'flowbite-react'
import useBreakPoint from '../../hooks/useBreakPoint'

const Contact: React.FC = () => {
  /** ------------------------------ States ------------------------------ */
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [company, setCompany] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [message, setMessage] = useState<string>('')

  /** ------------------------------ Hooks ------------------------------ */
  const { lg } = useBreakPoint()

  /** ------------------------------ Logics ------------------------------ */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const subject = `Contact from ${name}`
    const body = `Name: ${name}\n${company?.trim().length ? `Company: ${company}\n` : ''}${
      phone?.trim().length ? `Phone: ${phone}\n` : ''
    }\n\n${message}`
    const mailtoLink = `mailto:${BRAND_EMAIL_PRIMARY}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
      body
    )}`

    window.location.href = mailtoLink
  }

  /** ------------------------------ UI Renderer ------------------------------ */
  return (
    <>
      <section className="section">
        <div
          className="container mx-auto px-4 md:px-6 pt-12 pb-6 md:pt-24 md:pb-16"
          style={{ minHeight: lg ? window?.innerHeight - 414 : undefined }}
        >
          <div className="grid grid-cols-12 gap-8 md:gap-16 lg:gap-8">
            <div className="col-span-12 lg:col-span-6">
              <h1 className="text-xl md:text-3xl lg:text-[40px] font-bold mb-6">Get In Touch</h1>
              <p className="mb-6 ">
                We excel in building digital experiences that are ready for the future. Let us know what you need; we're
                excited to partner with you.
              </p>

              <Link to={`mailto:${BRAND_EMAIL_PRIMARY}`}>
                <span className="hover_underline after:scale-x-100 font-medium">{BRAND_EMAIL_PRIMARY}</span>
              </Link>

              <div className="mt-16">
                <h6 className=" font-medium text-lg">Other ways to get in touch with us:</h6>

                <div className="flex flex-col gap-4 mt-6 md:mt-8">
                  <div className="flex gap-3 items-center ">
                    <PhoneIcon />
                    {BRAND_PHONE_NUMBER_ARRAY.map((phone, index) => (
                      <Link to={`tel:${phone}`} key={index + 1}>
                        <span>
                          {phone} {phone.startsWith('+95') ? '(MM)' : '(TH)'}
                          {index + 1 !== BRAND_PHONE_NUMBER_ARRAY.length && ', '}
                        </span>
                      </Link>
                    ))}
                  </div>
                  <div className="flex gap-3 items-center ">
                    <EmailIcon />
                    <Link to={`mailto:${BRAND_EMAIL_SECONDARY}?subject=Service%20Request`}>
                      <span>{BRAND_EMAIL_SECONDARY}</span>
                    </Link>
                  </div>
                  <div className="flex gap-3 items-center ">
                    <LocationIcon />
                    <span>{BRAND_LOCATION}</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Contact Form */}
            <div className="col-span-12 lg:col-span-6 lg:pl-28">
              <form onSubmit={handleSubmit}>
                <FloatingLabel
                  value={name}
                  name="name"
                  type="text"
                  variant="outlined"
                  label="Your Name"
                  className="mb-6"
                  required
                  onChange={(e) => setName(e.target.value)}
                />
                {/* <FloatingLabel
                  name="email"
                  type="email"
                  variant="outlined"
                  label="Your Email"
                  className="mb-6"
                  required
                /> */}

                <FloatingLabel
                  value={company}
                  name="company"
                  type="text"
                  variant="outlined"
                  label="Your Company Name (optional)"
                  className="mb-6"
                  onChange={(e) => setCompany(e.target.value)}
                />
                <FloatingLabel
                  value={phone}
                  name="phone"
                  type="tel"
                  variant="outlined"
                  label="Your Contact Number (optional)"
                  className="mb-6"
                  onChange={(e) => setPhone(e.target.value)}
                />

                <div className="relative">
                  <textarea
                    value={message}
                    id="floating_filled"
                    className="block w-full px-3 md:px-4 py-2.5 text-sm md:text-base text-dark focus:border-primary bg-transparent rounded-lg border border-grayCustom appearance-none focus:outline-none focus:ring-0  peer"
                    placeholder=""
                    rows={8}
                    name="message"
                    required
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  <label
                    htmlFor="floating_filled"
                    className="absolute text-sm text-grayCustom duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                  >
                    Message
                  </label>
                </div>

                <div className="text-center lg:text-end mt-7">
                  <Button color="primary" type="submit" className="shadow-card" label="Submit" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
