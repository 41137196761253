import React, { Ref, useEffect, useRef, useState } from 'react'
import ChevronBackOutline from '../../atoms/Icons/ChevronBackOutline'
import ChevronForward from '../../atoms/Icons/ChevronForward'
import CloseOutlinedIcon from '../../atoms/Icons/CloseOutlinedIcon'
import lightTheme from '../../../theme/light-theme'

type ImageGalleryProps = {
  images: string[]
  isVideo?: boolean
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images, isVideo }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const imageRef = useRef<HTMLImageElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)

  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([])

  // const handleClickOutside = (event: MouseEvent) => {
  //   const isOutside = buttonRefs.current.every((ref) => ref && !ref.contains(event.target as Node))

  //   if (ref.current && !ref.current.contains(event.target as Node) && isOutside) {
  //     setIsOpen(false)
  //   }
  // }

  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClickOutside)
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside)
  //   }
  // }, [])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load()
    }
  }, [currentImageIndex])

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isOpen])

  const openGallery = (index: number) => {
    setCurrentImageIndex(index)
    setIsOpen(true)
  }

  const closeGallery = () => {
    setIsOpen(false)
  }

  const showPrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1))
  }

  const showNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0))
  }

  const addRef = (el: HTMLButtonElement | null) => {
    if (el && !buttonRefs.current.includes(el)) {
      buttonRefs.current.push(el)
    }
  }

  return (
    <div>
      <button className="hover_underline after:scale-x-100 text-sm" onClick={() => openGallery(0)}>
        View gallery
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-dark bg-opacity-75 backdrop-blur-[1px] flex justify-center items-center z-50">
          <div className="container mx-auto relative h-full">
            <button className="gallery_btn bg-opacity-0 top-10 lg:top-10 right-0" onClick={closeGallery} ref={addRef}>
              {/* &times; */}
              <CloseOutlinedIcon color={lightTheme.lightGray} size={32} />
            </button>

            <div className="flex justify-center items-center py-11 h-full">
              {isVideo ? (
                <video className="w-full lg:w-2/3 aspect-video  shadow-card bg-secondary" controls ref={videoRef}>
                  <source src={images[currentImageIndex]} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={images[currentImageIndex]}
                  alt="Gallery"
                  className="w-full h-auto lg:w-auto lg:h-[90vh]  object-contain bg-white gallery_image"
                  ref={imageRef}
                />
              )}
            </div>

            <button onClick={showPrevImage} className="gallery_btn left-0 top-1/2" ref={addRef}>
              <ChevronBackOutline />
            </button>

            <button className="gallery_btn right-0 top-1/2 " onClick={showNextImage} ref={addRef}>
              <ChevronForward />
            </button>

            <span className="absolute bottom-2 left-1/2 text-lightGray">
              {currentImageIndex + 1}/{images?.length}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default ImageGallery
