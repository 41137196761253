import { Theme } from '../types/theme'

const lightTheme: Theme = {
  primary: '#FDD64A',
  secondary: '#3A3B3C',
  warningLight: '#FEE48A',
  warningLightest: '#FFF2CA',
  dark: '#3A3B3C',
  darkestGray: '#626163',
  darkGray: '#89898A',
  grayCustom: '#B1B0B1',
  lightGray: '#D8D8D8',
  white: '#FFFFFF',
  dividerColor: 'rgba(0, 0, 0, 0.08)',
  borderColor: 'rgba(0, 0, 0, 0.15)',
  darkBlue: '#252E5F',
  mute: '#666666',
  light: {
    100: 'rgba(216, 216, 216,0.1)',
    200: 'rgba(216, 216, 216,0.2)',
    300: 'rgba(216, 216, 216,0.3)',
    400: 'rgba(216, 216, 216,0.4)',
    500: 'rgba(216, 216, 216,0.5)',
    600: 'rgba(216, 216, 216,0.6)',
    700: 'rgba(216, 216, 216,0.7)',
    800: 'rgba(216, 216, 216,0.8)',
    900: 'rgba(216, 216, 216,0.9)'
  }
}

export default lightTheme
