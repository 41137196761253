export const BRAND_COMPANY_NAME = process.env.REACT_APP_APP_BRAND_COMPANY_NAME

export const BRAND_EMAIL_PRIMARY = 'hello@nestmarketingmm.com'

export const BRAND_EMAIL_SECONDARY = 'startwithnest@gmail.com'

export const BRAND_EMAIL_TERTIARY = 'info@nestmarketingmm.com'

export const BRAND_FACEBOOK_URL = 'https://www.facebook.com/NestMarketingMyanmar'

export const BRAND_MESSENGER_URL = 'http://m.me/NestMarketingMyanmar'

export const BRAND_LINKEDIN_URL = 'https://www.linkedin.com/company/nestmarketingmm/'

export const BRAND_YOUTUBE_URL = 'https://www.youtube.com/'

export const BRAND_TIKTOK_URL = 'https://www.tiktok.com/'

export const BRAND_INSTAGRAM_URL = 'https://www.instagram.com/'

export const BRAND_PHONE_NUMBER_STRING = '+9595088225 (MM), +66626100643 (TH)'

export const BRAND_PHONE_NUMBER_ARRAY = ['+9595088225', '+66626100643']

export const BRAND_LOCATION = '14/401, Shwe Htee Housing 2, Mayangone Tsp, Yangon'

export const BRAND_STARTED_YEAR = 2019
