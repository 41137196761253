import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import TitleBase from '../../../../atoms/Typography/TitleBase'

interface MenuItemProps {
  name: string
  path: string
  onClick?(): void
  footer?: boolean
}

const MenuItem: React.FC<MenuItemProps> = ({ name, path, onClick, footer }) => {
  const location = useLocation()
  const isActive = location.pathname === path

  return (
    <Link to={path} onClick={onClick}>
      <TitleBase className={`${footer ? 'text-white' : isActive ? 'active_menu' : ''} hover_underline`}>
        {name}
      </TitleBase>
    </Link>
  )
}

export default MenuItem
