import React from 'react'
import lightTheme from '../../../theme/light-theme'

interface MessengerProps {
  size?: number
  color?: string
  className?: string
}

const Messenger: React.FC<MessengerProps> = ({ size = 24, color, className }) => {
  return (
    // <svg
    //   width={size}
    //   height={size}
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className={className}
    // >
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M22.5 12.9578C22.5 7.15939 17.7984 2.45782 12 2.45782C6.20156 2.45782 1.5 7.15939 1.5 12.9578C1.5 18.1984 5.33906 22.5424 10.3594 23.3308V15.9939H7.69266V12.9578H10.3594V10.6445C10.3594 8.01345 11.9273 6.55892 14.3255 6.55892C15.4744 6.55892 16.6763 6.76423 16.6763 6.76423V9.34845H15.3516C14.048 9.34845 13.6402 10.1575 13.6402 10.9891V12.9578H16.552L16.087 15.9939H13.6406V23.3317C18.6609 22.5438 22.5 18.1999 22.5 12.9578Z"
    //     fill={color || lightTheme.secondary}
    //   />
    // </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width={24}
      height={24}
      className={className}
      fill="none"
    >
      <path
        d="M 25 2 C 12.300781 2 2 11.601563 2 23.5 C 2 29.800781 4.898438 35.699219 10 39.800781 L 10 48.601563 L 18.601563 44.101563 C 20.699219 44.699219 22.800781 44.898438 25 44.898438 C 37.699219 44.898438 48 35.300781 48 23.398438 C 48 11.601563 37.699219 2 25 2 Z M 27.300781 30.601563 L 21.5 24.398438 L 10.699219 30.5 L 22.699219 17.800781 L 28.601563 23.699219 L 39.101563 17.800781 Z"
        fill={color || lightTheme.secondary}
      />
    </svg>
  )
}

export default Messenger
