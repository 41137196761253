enum Menu {
  Home = 'HOME',
  About = 'ABOUT',
  Showcase = 'SHOWCASE',
  Contact = 'CONTACT'
}

const MenuLabel: Record<Menu, string> = {
  HOME: 'Home',
  SHOWCASE: 'Showcase',
  ABOUT: 'About',
  CONTACT: 'Contact'
}

const MenuPath: Record<Menu, string> = {
  HOME: '/',
  SHOWCASE: '/showcase',
  ABOUT: '/about',
  CONTACT: '/contact'
}

export { MenuPath, MenuLabel, Menu }
