const showcases = [
  {
    id: 1,
    name: 'Social Media Design',
    description:
      'Social media design plays a pivotal role in capturing attention, conveying brand messages, and bringing up engagements. Investing in high-quality and strategic designs is essential for building a strong online presence and maximizing the impact of your social media efforts.',
    preview_images: [
      '/assets/showcase/social_media_designs/i_chip_you.jpg',
      '/assets/showcase/social_media_designs/mi_san.jpg',
      'assets/showcase/social_media_designs/arsh.jpg',
      '/assets/showcase/social_media_designs/skin_needs.jpg'
    ],
    all_images: [
      '/assets/showcase/social_media_designs/i_chip_you.jpg',
      '/assets/showcase/social_media_designs/mi_san.jpg',
      'assets/showcase/social_media_designs/arsh.jpg',
      '/assets/showcase/social_media_designs/skin_needs.jpg',
      '/assets/showcase/social_media_designs/five_g.jpg',
      '/assets/showcase/social_media_designs/kids.jpg',
      '/assets/showcase/social_media_designs/acne.jpg',
      '/assets/showcase/social_media_designs/chicken_mala.jpg',
      '/assets/showcase/social_media_designs/de_laundrymax.jpg',
      '/assets/showcase/social_media_designs/fresh.jpg',
      '/assets/showcase/social_media_designs/ielts.png',
      '/assets/showcase/social_media_designs/introduce.jpg',
      '/assets/showcase/social_media_designs/lo_yar.jpg',
      '/assets/showcase/social_media_designs/loacker.jpg',
      '/assets/showcase/social_media_designs/mab.jpg',
      '/assets/showcase/social_media_designs/may_coo.jpg',
      '/assets/showcase/social_media_designs/n_skin.jpg',
      '/assets/showcase/social_media_designs/pink_salt.jpg',
      '/assets/showcase/social_media_designs/water_dat.jpg',
      '/assets/showcase/social_media_designs/yay_nway.png'
    ]
  },
  {
    id: 2,
    name: 'Logo Creation',
    description:
      'Logo creation is essential for establishing a brand identity, making a positive first impression, and communicating the values of a business. A well-designed logo contributes to professional- ism, consistency, and differentiation, playing a vital role in the success of a brand.',
    preview_images: [
      '/assets/showcase/logo_creation/j.png',
      '/assets/showcase/logo_creation/dear_nest_clothing.png',
      '/assets/showcase/logo_creation/pyaesone.png',
      '/assets/showcase/logo_creation/eain_gyi_yakhine.png'
    ],
    all_images: [
      '/assets/showcase/logo_creation/j.png',
      '/assets/showcase/logo_creation/dear_nest_clothing.png',
      '/assets/showcase/logo_creation/pyaesone.png',
      '/assets/showcase/logo_creation/eain_gyi_yakhine.png',
      '/assets/showcase/logo_creation/dolphilmis_diary.png',
      '/assets/showcase/logo_creation/element_medical_group.png',
      '/assets/showcase/logo_creation/sky_gems_jewellery.png'
    ]
  },
  {
    id: 3,
    name: 'POSM Design',
    description:
      'POSM design plays a crucial role in driving sales, reinforcing brand identity, and enhancing the overall customer experience at the point of sale. Thoughtful and visually appealing POS materials contribute to a positive and impactful retail presence for businesses.',
    preview_images: ['/assets/showcase/posm/preview.jpg'],
    all_images: [
      '/assets/showcase/posm/posm_1.png',
      '/assets/showcase/posm/posm_2.jpg',
      '/assets/showcase/posm/posm_3.jpg'
    ]
  },
  {
    id: 4,
    name: 'Packaging Design',
    description:
      'Packaging design plays an important role in brand identity, consumer perception, and product protection. A well-thought-out packaging design not only attracts customers but also communicates the brand story, values, and product information effectively.',
    preview_images: [
      '/assets/showcase/packaging_design/packaging_design_1.jpg',
      '/assets/showcase/packaging_design/packaging_design_2.png'
    ],
    all_images: [
      '/assets/showcase/packaging_design/packaging_design_1.jpg',
      '/assets/showcase/packaging_design/packaging_design_2.png'
    ]
  },
  {
    id: 5,
    name: 'Character Drawing',
    description:
      'Character drawing in marketing is a powerful tool for creating a unique brand identity, fostering emotional engagement, and differentiating a brand in the competitive marketplace. It adds a creative and human element to brand communication, making the marketing strategy more memorable and impactful.',
    preview_images: [
      '/assets/showcase/character_drawing/character_drawing_1.jpg',
      '/assets/showcase/character_drawing/character_drawing_2.jpg',
      '/assets/showcase/character_drawing/character_drawing_3.jpg',
      '/assets/showcase/character_drawing/character_drawing_4.jpg'
    ],
    all_images: [
      '/assets/showcase/character_drawing/character_drawing_1.jpg',
      '/assets/showcase/character_drawing/character_drawing_2.jpg',
      '/assets/showcase/character_drawing/character_drawing_3.jpg',
      '/assets/showcase/character_drawing/character_drawing_4.jpg'
    ]
  },
  {
    id: 6,
    name: 'Photo and Video Production',
    description:
      "Photo and video production are indispensable tools for modern businesses, serving as powerful means of communication, brand representation, and engagement with the target audience. We provide high-quality photo and video services coupled with innovative idea generation to bring your brand's narrative to life.",
    preview_images: ['/assets/showcase/video_production/preview.jpg'],
    all_images: [
      '/assets/showcase/video_production/video_production_1.jpg',
      '/assets/showcase/video_production/video_production_2.jpg',
      '/assets/showcase/video_production/video_production_3.jpg'
    ]
  },
  {
    id: 7,
    name: 'Collaborating with Social influencers',
    description:
      'Collaborating with Social influencers is instrumental in expanding brand reach, building credibility, and creating authentic connections with a targeted audience. It has evolved into a strategic and effective marketing approach in the digital era.',
    preview_images: ['/assets/showcase/influencer/preview.jpg'],
    all_images: ['/assets/showcase/influencer/preview.jpg']
  },
  {
    id: 8,
    name: 'Event Planning',
    description:
      'Event planning is a multifaceted strategy that goes beyond logistics. It serves as a powerful tool for brand building, community engagement, networking, and various other aspects that contribute to the overall success and growth of a business.',
    preview_images: ['/assets/showcase/event_planning/preview.jpg'],
    all_images: [
      '/assets/showcase/event_planning/super_k_1.jpg',
      '/assets/showcase/event_planning/super_k_2.jpg',
      '/assets/showcase/event_planning/super_k_3.jpg',
      '/assets/showcase/event_planning/super_k_4.jpg',
      '/assets/showcase/event_planning/super_k_5.jpg',
      '/assets/showcase/event_planning/super_k_6.jpg',
      '/assets/showcase/event_planning/super_k_7.jpg',
      '/assets/showcase/event_planning/super_k_8.jpg',
      '/assets/showcase/event_planning/event_1.jpg',
      '/assets/showcase/event_planning/event_2.jpg',
      '/assets/showcase/event_planning/event_3.jpg',
      '/assets/showcase/event_planning/event_4.jpg',
      '/assets/showcase/event_planning/event_5.jpg',
      '/assets/showcase/event_planning/event_6.jpg',
      '/assets/showcase/event_planning/msi_1.jpg',
      '/assets/showcase/event_planning/msi_2.jpg',
      '/assets/showcase/event_planning/msi_3.jpg',
      '/assets/showcase/event_planning/msi_4.jpg',
      '/assets/showcase/event_planning/msi_5.jpg',
      '/assets/showcase/event_planning/msi_6.jpg',
      '/assets/showcase/event_planning/msi_7.jpg',
      '/assets/showcase/event_planning/msi_8.jpg'
    ]
  },
  {
    id: 9,
    name: 'Motion Graphic',
    description:
      'Motion graphics are a powerful tool in the world of digital marketing, combining animation, graphic design, and visual effects to create engaging and visually appealing content. They help to communicate complex ideas in a simple and compelling way, making them an excellent choice for brands looking to capture and retain the attention of their audience.',
    preview_images: ['/assets/showcase/motion_graphic/vivo.mp4'],
    all_images: [
      '/assets/showcase/motion_graphic/vivo.mp4',
      '/assets/showcase/motion_graphic/loyar.mp4',
      '/assets/showcase/motion_graphic/de_laundrymax.mp4',
      '/assets/showcase/motion_graphic/late_ma_kya.mp4',
      '/assets/showcase/motion_graphic/introduce.mp4',
      '/assets/showcase/motion_graphic/loacker.mp4',
      '/assets/showcase/motion_graphic/mab.mp4',
      '/assets/showcase/motion_graphic/n_skin.mp4',
      '/assets/showcase/motion_graphic/pnapan.mp4',
      '/assets/showcase/motion_graphic/post_3.mp4',
      '/assets/showcase/motion_graphic/skinfinity_laser_hair.mp4',
      '/assets/showcase/motion_graphic/sport_collection_myanmar.mp4'
    ],
    isVideo: true
  }
]

export default showcases
