import React from 'react'
import lightTheme from '../../../theme/light-theme'

interface ChevronForwardProps {
  size?: number
  color?: string
  className?: string
}

const ChevronForward: React.FC<ChevronForwardProps> = ({ size = 24, color, className }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.8295 4.4545C8.26884 4.01517 8.98116 4.01517 9.4205 4.4545L16.1705 11.2045C16.6098 11.6438 16.6098 12.3562 16.1705 12.7955L9.4205 19.5455C8.98116 19.9848 8.26884 19.9848 7.8295 19.5455C7.39017 19.1062 7.39017 18.3938 7.8295 17.9545L13.784 12L7.8295 6.0455C7.39017 5.60616 7.39017 4.89384 7.8295 4.4545Z"
        fill={color || lightTheme.secondary}
      />
    </svg>
  )
}

export default ChevronForward
