import { RouterProvider } from 'react-router-dom'
import router from './router'
import { Flowbite } from 'flowbite-react'
import customTheme from './theme'

const App = () => {
  return (
    <Flowbite theme={{ theme: customTheme }}>
      <div className="text-dark">
        <RouterProvider router={router} />
      </div>
    </Flowbite>
  )
}

export default App
