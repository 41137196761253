import { CustomFlowbiteTheme } from 'flowbite-react'

const customTheme: CustomFlowbiteTheme = {
  button: {
    label: 'text-sm md:text-base',
    base: 'text-sm md:text-base',
    color: {
      primary:
        'max-w-max bg-primary px-4 md:px-5 lg:px-6 text-white rounded-lg hover:bg-secondary hover:text-white transition-all focus:ring-transparent py-0.5',
      secondary:
        'max-w-max bg-secondary px-4 md:px-5 lg:px-6 text-white rounded-lg hover:bg-primary hover:text-secondary transition-all focus:ring-transparent py-0.5'
    }
  },
  textInput: {
    field: {
      input: {
        base: '!rounded-lg !px-3 md:!px-4 w-full text-sm md:text-base placeholder-darkGray focus:ring-transparent',
        colors: {
          light:
            '!border-grayCustom focus:border-primary focus:ring-transparent dark:border-primary dark:bg-primary dark:focus:border-primary dark:focus:ring-primary',

          failure:
            'border-red-500 bg-white focus:border-red-500 focus:ring-transparent dark:border-primary dark:bg-primary dark:focus:border-red-500 dark:focus:ring-primary'
        }
      }
    }
  },
  floatingLabel: {
    input: {
      default: {
        outlined: {
          sm: 'peer block w-full appearance-none rounded-lg border border-grayCustom bg-transparent px-3 md:px-4 py-2.5 text-xs md:text-sm text-dark focus:border-primary focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500',
          md: 'peer block w-full appearance-none rounded-lg border border-grayCustom bg-transparent px-3 md:px-4 py-2.5 text-sm md:text-base text-dark focus:border-primary focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500'
        }
      }
    },
    label: {
      default: {
        outlined: {
          sm: 'absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-xs text-grayCustom transition-transform duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-primary dark:bg-dark dark:text-gray-400 peer-focus:dark:text-blue-500',
          md: 'absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-grayCustom transition-transform duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-primary dark:bg-dark dark:text-gray-400 peer-focus:dark:text-blue-500'
        }
      }
    }
  },
  textarea: {
    base: 'block w-full !rounded-sm !px-3 border-border-color disabled:cursor-not-allowed disabled:opacity-50 text-sm md:text-base',
    colors: {
      light:
        'border-border-color  bg-white  focus:border-primary focus:ring-transparent dark:border-primary dark:bg-primary dark:focus:border-primary dark:focus:ring-primary',

      failure:
        'border-red-500 bg-white focus:border-red-500 focus:ring-transparent dark:border-primary dark:bg-primary dark:focus:border-red-500 dark:focus:ring-primary'
    }
  }
}

export default customTheme
