import React, { HtmlHTMLAttributes } from 'react'

interface TitleBaseProps extends HtmlHTMLAttributes<HTMLHeadingElement> {}

const TitleBase: React.FC<TitleBaseProps> = ({ ...props }) => {
  return (
    <h6 {...props} className={`${props.className ? props.className : ''} font-medium text-sm xl:text-base`}>
      {props.children}
    </h6>
  )
}

export default TitleBase
