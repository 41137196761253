import React from 'react'
import { Link } from 'react-router-dom'
import { BRAND_EMAIL_PRIMARY } from '../../../constants/brandInfo'

interface SloganBannerProps {}

const SloganBanner: React.FC<SloganBannerProps> = () => {
  // const handleOnClick = useCallback(() => {
  //   const mailtoLink = `mailto:${BRAND_EMAIL_PRIMARY}?subject=${encodeURIComponent('Request for Information')}`
  //   window.location.href = mailtoLink
  // }, [])

  return (
    <section className="bg-primary py-8 md:py-12 lg:py-14 text-center slogan-container">
      <h2 className=" font-slogan text-base md:text-2xl text-dark mb-3 md:mb-5">
        All you have to do is fly from the Nest
      </h2>

      <Link
        to={`mailto:${BRAND_EMAIL_PRIMARY}?subject=${encodeURIComponent('Request for Information')}`}
        className=" text-xl md:text-2xl lg:text-[32px] btn_link after:bg-white after:h-[3px] slogan"
      >
        Let’s Talk
      </Link>
    </section>
  )
}

export default SloganBanner
