import { Button } from 'flowbite-react'
import React, { useCallback } from 'react'
import { BRAND_EMAIL_PRIMARY } from '../../../constants/brandInfo'

interface HeroBannerProps {}

const HeroBanner: React.FC<HeroBannerProps> = () => {
  /** ------------------------------ Logics ------------------------------ */
  const handleOnClick = useCallback(() => {
    const mailtoLink = `mailto:${BRAND_EMAIL_PRIMARY}?subject=${encodeURIComponent('Request for Information')}`
    window.location.href = mailtoLink
  }, [])

  /** ------------------------------ UI ------------------------------ */

  return (
    <section className="mt-3 md:mt-0 h-[50dvh] md:h-[75dvh] lg:h-[80dvh] hero-banner">
      <div className="container m-auto h-full">
        <div className="flex flex-col h-full justify-center gap-6 pl-10 pb-12">
          <h1 className="text-xl md:text-[40px] md:leading-relaxed font-semibold">
            Drive your <span className="text-primary">Growth</span> <br /> with Nest Marketing
          </h1>
          <p className="max-w-[75%] md:max-w-[50%]">
            Our commitment to excellence and passion for creativity have enabled us to build lasting relationships and
            contribute to the success stories
          </p>

          <Button color="secondary" className="mt-7" label="GET IN TOUCH" onClick={handleOnClick} />
        </div>
      </div>
    </section>
  )
}

export default HeroBanner
