import React from 'react'
import lightTheme from '../../../theme/light-theme'

interface PhoneIconProps {
  size?: number
  color?: string
  className?: string
}

const PhoneIcon: React.FC<PhoneIconProps> = ({ size = 24, color, className }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M24.4377 29.9999C23.2177 29.9999 21.504 29.5587 18.9377 28.1249C15.8171 26.3749 13.4033 24.7593 10.2996 21.6637C7.30708 18.673 5.85084 16.7368 3.81271 13.028C1.51021 8.84053 1.90271 6.64553 2.34146 5.70741C2.86396 4.58616 3.63521 3.91553 4.63208 3.24991C5.1983 2.87893 5.7975 2.56092 6.42208 2.29991C6.48458 2.27303 6.54271 2.24741 6.59458 2.22428C6.90396 2.08491 7.37271 1.87428 7.96646 2.09928C8.36271 2.24803 8.71646 2.55241 9.27021 3.09928C10.4058 4.21928 11.9577 6.71366 12.5302 7.93866C12.9146 8.76428 13.169 9.30928 13.1696 9.92053C13.1696 10.6362 12.8096 11.188 12.3727 11.7837C12.2908 11.8955 12.2096 12.0024 12.1308 12.1062C11.6552 12.7312 11.5508 12.9118 11.6196 13.2343C11.759 13.8824 12.7983 15.8118 14.5065 17.5162C16.2146 19.2205 18.0883 20.1943 18.739 20.333C19.0752 20.4049 19.2596 20.2962 19.9046 19.8037C19.9971 19.733 20.0921 19.6599 20.1915 19.5868C20.8577 19.0912 21.384 18.7405 22.0827 18.7405H22.0865C22.6946 18.7405 23.2152 19.0043 24.0777 19.4393C25.2027 20.0068 27.7721 21.5387 28.899 22.6755C29.4471 23.228 29.7527 23.5805 29.9021 23.9762C30.1271 24.5718 29.9152 25.0387 29.7771 25.3512C29.754 25.403 29.7283 25.4599 29.7015 25.523C29.4384 26.1465 29.1185 26.7444 28.7458 27.3093C28.0815 28.303 27.4083 29.0724 26.2846 29.5955C25.7075 29.8685 25.076 30.0067 24.4377 29.9999Z"
        fill={color || lightTheme.secondary}
      />
    </svg>
  )
}

export default PhoneIcon
