import React from 'react'
import { useToggle } from 'usehooks-ts'
import { Navbar } from 'flowbite-react'
import Logo from '../../../../atoms/Logo'
import MobileMenuSlider from './MobileMenuSlider'
import MenuOutlinedIcon from '../../../../atoms/Icons/MenuOutlinedIcon'

const MobileNav = () => {
  const [isOpen, toggleMenu] = useToggle(false)

  return (
    <div className={`md:hidden w-full mt-3`}>
      {/* Brand Logo */}
      <div className="flex justify-between items-center w-full">
        <Navbar.Brand as={'a'} href="/">
          <Logo className="!h-20" />
        </Navbar.Brand>

        {/* Toggle Button */}
        <button className=" md:hidden" onClick={toggleMenu}>
          <MenuOutlinedIcon />
        </button>
      </div>

      {/* Menu slider */}
      <MobileMenuSlider isOpen={isOpen} toggleMenu={toggleMenu} />

      {isOpen && <div className="mobile_nav_backdrop"></div>}
    </div>
  )
}

export default MobileNav
