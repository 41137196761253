import { Navbar } from 'flowbite-react'
import DesktopMenuBar from './components/DesktopNavBar'
import MobileNav from './components/MobileNav'
import React, { useCallback, useEffect, useState } from 'react'

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  // const [showHeader, setShowHeader] = useState<boolean>(true)
  // const [lastScrollY, setLastScrollY] = useState<number>(0)

  // const handleScroll = useCallback(() => {
  //   const currentScrollY = window.scrollY

  //   if (currentScrollY < lastScrollY && currentScrollY > window.innerHeight) {
  //     setShowHeader(true)
  //   } else {
  //     setShowHeader(false)
  //   }
  //   setLastScrollY(currentScrollY)
  // }, [lastScrollY])

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll)
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll)
  //   }
  // }, [handleScroll, lastScrollY])

  return (
    <>
      <header className={'w-full bg-white'}>
        <div className="container mx-auto">
          <Navbar rounded className="px-4 md:px-2">
            <MobileNav />
            <DesktopMenuBar />
          </Navbar>
        </div>
      </header>

      {/* Floating Header */}
      {/* <header
        className={`fixed top-0 left-0 w-full bg-white z-50 transition-transform duration-300 ${
          showHeader ? 'translate-y-0' : '-translate-y-full'
        } hidden md:block`}
      >
        <div className="container mx-auto">
          <Navbar rounded className="px-4 md:px-2">
            <DesktopMenuBar />
          </Navbar>
        </div>
      </header> */}
    </>
  )
}

export default Header
