const clients = [
  {
    id: 1,
    name: 'MAB',
    logoUrl: 'assets/client-logos/mab.png'
  },
  {
    id: 2,
    name: 'Skin Needs',
    logoUrl: 'assets/client-logos/skin_need.png'
  },
  {
    id: 3,
    name: 'Vivo',
    logoUrl: 'assets/client-logos/vivo.png'
  },
  {
    id: 4,
    name: 'Smart Home',
    logoUrl: 'assets/client-logos/smart_home.png'
  },
  {
    id: 5,
    name: 'Loyar Myanmar',
    logoUrl: 'assets/client-logos/loyarmyanmar.png'
  },
  {
    id: 6,
    name: 'Laser Care Clinic',
    logoUrl: 'assets/client-logos/lcc.png'
  },
  {
    id: 7,
    name: 'May Coo',
    logoUrl: 'assets/client-logos/may_coo.png'
  },
  {
    id: 8,
    name: 'Plume Smallbaby',
    logoUrl: 'assets/client-logos/psb.png'
  },
  {
    id: 9,
    name: 'Y Shaper',
    logoUrl: 'assets/client-logos/y_shaper.png'
  },
  {
    id: 10,
    name: 'Pan Pan',
    logoUrl: 'assets/client-logos/pan_pan.png'
  },
  {
    id: 11,
    name: 'Ye Ye',
    logoUrl: 'assets/client-logos/yeye.png'
  },
  {
    id: 12,
    name: 'HIMALAYA',
    logoUrl: 'assets/client-logos/himalaya.jpg'
  }
]

export default clients
