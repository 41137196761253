import React, { useEffect, useState } from 'react'
import Header from '../Header'
import { Outlet } from 'react-router-dom'
import Footer from '../Footer'

import BackToTopIcon from '../../../atoms/Icons/BackToTopIcon'

const MainLayout: React.FC = () => {
  /** ------------------------------ States ------------------------------ */

  const [showBackToTopButton, setShowBackToTopButton] = useState(false)
  /** ------------------------------ Hooks ------------------------------ */

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const triggerPoint = window.innerHeight * 3 // Scroll threshold (3 times screen height)
      setShowBackToTopButton(scrollPosition >= triggerPoint)
    }

    window.addEventListener('scroll', handleScroll)

    // Clean up listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  /** ------------------------------ Logics ------------------------------ */

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  /** ------------------------------ UI ------------------------------ */
  return (
    <div className="h-full">
      <Header />

      <main>
        <Outlet />
      </main>

      <Footer />

      {showBackToTopButton && (
        <div className="fixed right-5 bottom-5 md:right-10 md:bottom-10 animate-bounce">
          <BackToTopIcon className="cursor-pointer" onClick={handleScrollToTop} />
        </div>
      )}
    </div>
  )
}

export default MainLayout
