import React from 'react'

interface OurServicesProps {}

const OurServices: React.FC<OurServicesProps> = () => {
  return (
    <section className="section bg-light-100">
      <div className="container mx-auto px-4 md:px-6">
        <div className="text-center">
          <h2 className="section_title mb-2">Our Services</h2>
          <p className="section_sub_title">What we offer</p>
        </div>

        <div className="grid grid-cols-12 gap-4 md:gap-5 lg:gap-8 mt-11 items-center">
          <div className="col-span-12 sm:col-span-6 lg:col-span-4 h-full">
            <ServiceCard
              name="Social Media Marketing"
              description='"Engage, Inspire, and Grow Your Audience"'
              imageUrl="assets/services-images/social-media.png"
            />
          </div>
          <div className="col-span-12 sm:col-span-6 lg:col-span-4 h-full">
            <ServiceCard
              name="Video Shooting"
              description='"Professional Visuals, Powerful Stories"'
              imageUrl="assets/services-images/video-production.png"
            />
          </div>
          <div className="col-span-12 sm:col-span-6 lg:col-span-4 h-full">
            <ServiceCard
              name="Photo Shooting"
              description='"Moments Frozen in Time"'
              imageUrl="assets/services-images/photo.png"
            />
          </div>

          <div className="col-span-12 sm:col-span-6 lg:col-span-4 h-full">
            <ServiceCard
              name="Content Marketing"
              description='"Strategic Storytelling for Your Brand"'
              imageUrl="assets/services-images/content.png"
            />
          </div>

          <div className="col-span-12 sm:col-span-6 lg:col-span-4 h-full">
            <ServiceCard
              name="Influencer Management"
              description='"Amplifying Your Brand through Trusted Influencers"'
              imageUrl="assets/services-images/influence.png"
            />
          </div>

          <div className="col-span-12 sm:col-span-6 lg:col-span-4 h-full">
            <ServiceCard
              name="Branding"
              description='"Defining Your Unique Identity"'
              imageUrl="assets/services-images/branding.png"
            />
          </div>

          <div className="col-span-12 sm:col-span-6 lg:col-span-4 h-full">
            <ServiceCard
              name="Media Buying"
              description='"Maximizing Reach, Minimizing Cost"'
              imageUrl="assets/services-images/media_buying.png"
            />
          </div>

          <div className="col-span-12 sm:col-span-6 lg:col-span-4 h-full">
            <ServiceCard
              name="POSM Designs"
              description='"Innovative Designs for Impactful Displays"'
              imageUrl="assets/services-images/poster.png"
            />
          </div>

          <div className="col-span-12 sm:col-span-6 lg:col-span-4 h-full">
            <ServiceCard
              name="Event Planning"
              description='"Detail-Oriented Planning for Seamless Events"'
              imageUrl="assets/services-images/time-planning.png"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurServices

interface ServiceCardProps {
  name: string
  description: string
  imageUrl: string
}

const ServiceCard: React.FC<ServiceCardProps> = ({ name, description, imageUrl }) => {
  return (
    <div className="service-card flex flex-col justify-center items-center gap-4 h-full">
      <div className={`service-card-image mb-6 md:mb-10`} style={{ backgroundImage: `url(${imageUrl})` }} />

      <h6 className="text-base md:text-xl font-medium text-center">{name}</h6>
      <p className="text-darkestGray text-center">{description}</p>
    </div>
  )
}
