import { useMediaQuery } from 'usehooks-ts'

type BreakpointsType = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

const Breakpoints: Record<BreakpointsType, number> = {
  xxs: 320,
  xs: 375,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536
}

const useBreakPoint = (): Record<BreakpointsType, boolean> => {
  const xxs = useMediaQuery(`(min-width: ${Breakpoints.xxs}px)`)
  const xs = useMediaQuery(`(min-width: ${Breakpoints.xs}px)`)
  const sm = useMediaQuery(`(min-width: ${Breakpoints.sm}px)`)
  const md = useMediaQuery(`(min-width: ${Breakpoints.md}px)`)
  const lg = useMediaQuery(`(min-width: ${Breakpoints.lg}px)`)
  const xl = useMediaQuery(`(min-width: ${Breakpoints.xl}px)`)
  const xxl = useMediaQuery(`(min-width: ${Breakpoints.xxl}px)`)

  return { xxs, xs, sm, md, lg, xl, xxl }
}

export default useBreakPoint
