import React from 'react'
import clients from '../../../data/clients'

interface FeaturedClientsProps {}

const FeaturedClients: React.FC<FeaturedClientsProps> = () => {
  return (
    <section className="section">
      <div className="container mx-auto px-4 md:px-6">
        <div className="text-center">
          <h2 className="section_title mb-2">Featured Clients</h2>
          <p className="section_sub_title">Brands we’ve worked with</p>
        </div>

        <p className="text-center mt-8 font-medium">
          We have provided services to over 90 clients across 25 different industries during 5 years.
        </p>

        <div className="grid grid-cols-12 gap-3 sm:gap-4 md:gap-5 lg:gap-8 px-4 sm:px-12 md:px-16 lg:px-24 xl:px-36 mt-12 md:mt-16 lg:mt-20 mb-5 md:mb-10 lg:mb-12">
          {clients.map((client) => (
            <div
              className="col-span-4 lg:col-span-3 bg-light-300 p-2 lg:p-3 hover:shadow-card transition-shadow"
              key={client.id}
            >
              <div
                className="w-full h-20 md:h-40  bg-center bg-no-repeat bg-contain"
                style={{ backgroundImage: `url(${client.logoUrl})` }}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default FeaturedClients
