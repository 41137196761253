import React from 'react'
import lightTheme from '../../../theme/light-theme'

interface FacebookIconProps {
  size?: number
  color?: string
  className?: string
}

const FacebookIcon: React.FC<FacebookIconProps> = ({ size = 24, color, className }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 12.9578C22.5 7.15939 17.7984 2.45782 12 2.45782C6.20156 2.45782 1.5 7.15939 1.5 12.9578C1.5 18.1984 5.33906 22.5424 10.3594 23.3308V15.9939H7.69266V12.9578H10.3594V10.6445C10.3594 8.01345 11.9273 6.55892 14.3255 6.55892C15.4744 6.55892 16.6763 6.76423 16.6763 6.76423V9.34845H15.3516C14.048 9.34845 13.6402 10.1575 13.6402 10.9891V12.9578H16.552L16.087 15.9939H13.6406V23.3317C18.6609 22.5438 22.5 18.1999 22.5 12.9578Z"
        fill={color || lightTheme.secondary}
      />
    </svg>
  )
}

export default FacebookIcon
