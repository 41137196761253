import React, { HtmlHTMLAttributes } from 'react'

interface BackToTopIconProps extends HtmlHTMLAttributes<HTMLDivElement> {}

const BackToTopIcon: React.FC<BackToTopIconProps> = ({ className, ...props }) => {
  return (
    <div
      {...props}
      className={`${className ? className : ''} 
      bg-white w-8 h-8  rounded-full flex justify-center items-center shadow-card
       hover:-translate-y-1 transition-transform
      `}
    >
      <img className={`w-[12px] `} src="/assets/icons/back-to-top-icon.svg" alt="Back to top icon" />
    </div>
  )
}

export default BackToTopIcon
