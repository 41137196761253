import React from 'react'
import { BRAND_STARTED_YEAR } from '../../constants/brandInfo'
import { getYearDifference } from '../../utils'

interface AboutProps {}

const About: React.FC<AboutProps> = () => {
  return (
    <>
      <section className="section">
        <div className="container mx-auto px-4 md:px-6 pt-12 pb-6 md:pt-24 md:pb-16">
          <div className="grid grid-cols-7 gap-8">
            <div className="col-span-7 md:col-span-3">
              <h1 className="text-xl md:text-5xl font-bold">About us</h1>
            </div>
            <div className="col-span-7 md:col-span-4">
              <p className=" text-base md:text-xl font-medium mb-8">
                <span className="text-primary font-semibold">Nest Marketing</span> was founded in October 2019, and we
                proudly celebrate over {getYearDifference(BRAND_STARTED_YEAR)} years of excellence in delivering
                comprehensive marketing solutions.
              </p>

              <p className="mb-8 ">
                Over the years, we've had the privilege of serving more than 80 clients across 20 diverse industries.
                Our commitment to excellence and passion for creativity have enabled us to build lasting relationships
                and contribute to the success stories of our clients.
              </p>

              <p>
                Our Mission goes beyond marketing; it's about bringing happiness to our beloved clients through the
                delivery of exceptional services. We believe that success is measured not just in numbers but in the
                smiles and satisfaction of our clients.
              </p>
            </div>
          </div>
        </div>
      </section>

      <hr className=" border-lightGray container mx-auto w-[90%] lg:w-full" />

      <section className="section">
        <div className="container mx-auto px-4 md:px-6 md:py-12">
          <div className="grid grid-cols-12 gap-8 lg:gap-12 xl:gap-24">
            <div className="col-span-12 lg:col-span-6 xl:col-span-7">
              <div className="block lg:hidden mb-12">
                <h2 className="section_title mb-2">Our Team</h2>
                <p className="section_sub_title">Meet our energetic team</p>
              </div>

              <div className="our-team-img-wrapper ">
                <img className="our-team-img" src="/assets/our-team/our-team.jpg" alt="Our team" />
              </div>
            </div>

            <div className="col-span-12 lg:col-span-6 xl:col-span-5">
              <div className="hidden lg:block">
                <h2 className="section_title mb-2">Our Team</h2>
                <p className="section_sub_title">Meet our energetic team</p>
              </div>

              <p className="mt-0 mb-8 md:mb-0 md:mt-8 font-medium">
                Our team is a dynamic blend of youth, creativity, and active minds. We bring a fresh and innovative
                approach to social media marketing, infusing each project with the enthusiasm and adaptability that
                comes with being young.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About
